<template>
  <div>
    <v-form ref="form" class="provider-form">
      <v-row>
        <logo-form
          ref="logo"
          :provider="provider"
          @change="onChangeLogo"
          :avatar-loading="uploadingLogo"
        />
      </v-row>
      <v-row>
        <v-col sm="5">
          <label class="text-field-label">Name</label>
          <v-text-field
            v-model="data.name"
            flat
            solo
            required
            class="mt-1"
            :error-messages="data.$getError('name')"
            @input="data.$clearError('name')"
          />

          <label class="text-field-label">Email</label>
          <v-text-field
            v-model="data.email"
            flat
            solo
            required
            class="mt-1"
            :error-messages="data.$getError('email')"
            @input="data.$clearError('email')"
          />

          <label class="text-field-label">Address</label>
          <v-autocomplete
            v-model="data.address"
            flat
            no-filter
            :items="locations"
            :error-messages="data.$getError('address')"
            item-value="description"
            item-text="description"
            @keyup="getLocations"
            :loading="loadingLocations"
            @input="data.$clearError('address')"
          />
          <label class="text-field-label">Phone Number</label>
          <v-text-field
            v-model="data.phone_number"
            type="number"
            flat
            solo
            required
            class="mt-1"
            :error-messages="data.$getError('phone_number')"
            @input="data.$clearError('phone_number')"
          />
        </v-col>
        <v-col sm="7">
          <label class="text-field-label">Description</label>
          <v-textarea
            v-model="data.description"
            flat
            solo
            required
            class="mt-1"
            rows="10"
            :error-messages="data.$getError('description')"
            @input="data.$clearError('description')"
          />
        </v-col>
      </v-row>

      <v-row class="mb-10">
        <v-btn color="primary" :loading="submiting" @click="updateProvider"
          >Update</v-btn
        >
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Form from '@/utils/form'
import LogoForm from './LogoForm.vue'
import Api from '@/services/api'

export default {
  name: 'ProviderInfo',
  components: {
    LogoForm
  },
  data() {
    return {
      uploadingLogo: false,
      submiting: false,
      data: new Form({
        name: '',
        email: '',
        phone_number: '',
        address: '',
        description: ''
      }),
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      loadingLocations: false,
      locations: []
    }
  },
  computed: {
    ...mapState({
      provider: state => state.provider.details
    })
  },
  mounted() {
    this.data.name = this.provider.name
    this.data.email = this.provider.email
    this.data.phone_number = this.provider.phone_number
    this.data.address = this.provider.address
    this.data.description = this.provider.description
    this.locations = [{ description: this.provider.address }]
  },
  methods: {
    onChangeLogo(blob) {
      this.uploadingLogo = true
      const form = new FormData()
      form.append('logo', blob)

      Api.post(`/admin/providers/${this.provider.id}/logo`, form).then(
        response => {
          this.$store.commit('provider/setProviderDetails', response.data.data)
          this.uploadingLogo = false
        }
      )
    },
    updateProvider() {
      this.submiting = true

      Api.put(`/admin/providers/${this.provider.id}`, {
        name: this.data.name,
        email: this.data.email,
        phone_number: this.data.phone_number,
        description: this.data.description,
        address: this.data.address
      })
        .then(response => {
          this.showSnackbar('Provider details successfully updated!', 'success')
          this.$store.commit('provider/setProviderDetails', response.data.data)
          this.data.reset()
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.data.$setErrors(error.response.data.errors)
          }
        })
        .finally(() => (this.submiting = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
    getLocations(e) {
      this.loadingLocations = true
      Api.get('/places', { params: { input: e.target.value.trim() } })
        .then(response => {
          this.locations = response.data.data
        })
        .finally(() => (this.loadingLocations = false))
    }
  }
}
</script>
